import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { EditIcon, MenuItemWithIcon, OverflowMenu } from '@local/web-design-system';
import {
    eventsState,
    EventType,
    PostsObject,
    projectState,
    subscriptionState,
    userState,
    UserType,
} from 'state-domains/domain';
import { i18nActionConstants } from 'state-domains/constants/mxdeposit.i18n';
import { PostLinkedObjectType } from 'state-domains/domain/events';
import { memo, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateToDisplay } from 'src/utilities/formatters';
import { ImageIcon } from 'src/components/FileUpload/ImageIcon';
import { AvatarCell } from 'src/components/AvatarGroup';
import { StringWithUsers } from 'src/components/StringWithUsers';
import { Comments, RepliesDropdown, ReplyButton } from 'src/components/Comments';
import { Lightbox } from 'src/components/Lightbox';
import { CommentFormWithMentionableUsers } from 'src/components/Comments/CommentForm';
import { downloadFileFromPostAttachment } from 'src/components/Events/Posts/Posts.utils';
import { TimeType } from 'src/utilities/types';

import { EventHeader, getItemName, getItemUrl, Seperator } from '../EventPostHeader';
import useEventReply from '../useEventReply';

import { useStyles } from './PostCard.styles';

const ActionContent = memo((props: { post: PostsObject; onClick: () => void }) => {
    const intl = useIntl();

    const {
        selectors: { currentUserId: currentUserIdSelector, currentRole: currentRoleSelector },
    } = userState;

    const currentUserId = useSelector(currentUserIdSelector);
    const currentRole = useSelector(currentRoleSelector);

    return (
        <Grid container direction="column" alignItems="center">
            <Grid container direction="column" alignItems="left">
                <Grid item>
                    <Button
                        disabled={
                            props.post.userId !== currentUserId && currentRole !== UserType.Admin
                        }
                        onClick={props.onClick}
                        sx={{
                            paddingLeft: '16px',
                            width: '100%',
                        }}
                        automation-id="action-events-edit-btn"
                    >
                        <MenuItemWithIcon
                            label={intl.formatMessage(i18nActionConstants.edit)}
                            IconElement={EditIcon}
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
});

export const PostCard = ({
    post,
    commentedId,
    setCommentedId,
    setEditedId,
    customReply,
}: {
    post: PostsObject;
    commentedId: string;
    setCommentedId: Function;
    setEditedId?: Function;
    customReply?: Function;
}) => {
    const {
        selectors: { users: usersSelector, activityObject: getActivityByIdSelector },
    } = subscriptionState;
    const {
        selectors: { getProjectByUidFromAll: getProjectByUidSelector },
    } = projectState;
    const {
        actions: { downloadPostAttachment },
    } = eventsState;

    const dispatch = useDispatch();

    const { handleAddEventReply, commentsOpen, setCommentsOpen, getCommentsData } = useEventReply(
        post.id,
        EventType.POST,
        customReply,
    );

    const { classes } = useStyles();
    const users = useSelector(usersSelector);
    const attachments = useMemo(() => Object.values(post.attachments ?? {}), [post]);
    const project = useSelector(getProjectByUidSelector(post?.linkedObject?.project ?? ''));
    const activity = useSelector(getActivityByIdSelector(post?.linkedObject?.activity ?? ''));

    const [viewAttachmentOpen, setViewAttachmentOpen] = useState(false);

    const onReply = useCallback((textValue: string) => {
        handleAddEventReply(textValue);
    }, []);

    const onEdit = useCallback(() => {
        if (setEditedId) {
            setEditedId(post.id);
        }
    }, [post]);

    const { itemName, itemUrl } = useMemo(
        () => ({
            itemName: getItemName(
                project?.name ?? '',
                activity.name ?? '',
                post?.linkedObject?.objectName ?? '',
                false,
            ),
            itemUrl: getItemUrl(
                post?.linkedObject?.objectType as PostLinkedObjectType,
                post?.linkedObject?.object ?? '',
                post?.linkedObject?.project ?? '',
            ),
        }),
        [post, project, activity],
    );

    const commentsData = useMemo(() => getCommentsData(post.replies), [post.replies]);

    const downloadFileFn = useCallback(
        () => downloadFileFromPostAttachment(attachments, post, downloadPostAttachment, dispatch),
        [attachments, post],
    );
    const memoizedLightboxDownloadFileFn = useMemo(() => downloadFileFn(), [downloadFileFn]);
    const memoizedOnClose = useCallback(() => setViewAttachmentOpen(false), []);

    return (
        <>
            {viewAttachmentOpen && (
                <Lightbox
                    isImago={false}
                    files={attachments}
                    onClose={memoizedOnClose}
                    downloadFileFn={memoizedLightboxDownloadFileFn}
                />
            )}
            <Grid
                automation-id={`post-card-${post.id}`}
                container
                flexDirection="column"
                sx={{ borderTop: '1px solid #C8C8C8', padding: '16px' }}
            >
                <Grid container wrap="nowrap">
                    <Grid paddingRight="8px">
                        <AvatarCell item={users[post.userId]} itemKey="profile" />
                    </Grid>
                    <Grid container overflow="hidden" automation-id={`post-header-${post.id}`}>
                        <Grid container alignItems="center" wrap="nowrap">
                            <EventHeader
                                userName={users[post.userId]?.profile.name ?? ''}
                                objectLinkData={{
                                    name: post.linkedObject?.objectName ?? '',
                                    url: itemUrl,
                                }}
                                tooltipOverride={itemName}
                            />
                        </Grid>
                        <Grid container alignItems="center">
                            <Typography className={classes.dimText}>
                                {formatDateToDisplay(post.updatedAt, TimeType.DATE)}
                            </Typography>
                            <Seperator />
                            <Typography className={classes.dimText}>
                                {formatDateToDisplay(post.updatedAt, TimeType.TIME)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {setEditedId && (
                        <Grid container height="40px" width="40px">
                            <OverflowMenu options={[]} size="small">
                                <ActionContent post={post} onClick={onEdit} />
                            </OverflowMenu>
                        </Grid>
                    )}
                </Grid>
                <Grid width="100%" padding="12px 0px">
                    <Grid>
                        {!!attachments.length && (
                            <Grid sx={{ float: 'left', paddingRight: '8px' }}>
                                <ImageIcon
                                    contentType={attachments[0].contentType}
                                    src={attachments[0].file}
                                    name=""
                                    index={0}
                                    downloadFile={() => {}}
                                    showLightBox={() => {
                                        setViewAttachmentOpen(true);
                                    }}
                                    deleteFile={() => {}}
                                    viewInImago={() => {}}
                                    readOnly={true}
                                    isDownloadable={false}
                                    isImago={false}
                                    isMulti={attachments.length > 1}
                                />
                            </Grid>
                        )}
                        <StringWithUsers content={post.content} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        flex={post.shareType === 'private' ? 0 : 1}
                        automation-id={`reply-dropdown-${post.id}`}
                    >
                        <RepliesDropdown
                            commentsCount={post.replies.length}
                            isOpen={commentsOpen}
                            setOpen={setCommentsOpen}
                        />
                    </Grid>
                    {post.shareType === 'private' && (
                        <Grid item flex={1} paddingLeft="8px">
                            <Tooltip
                                title={`Visible By: ${post.shareWith.map((x) => users[x]?.profile?.name ?? '').join(', ')}`}
                            >
                                <img
                                    src="/images/Visible.svg"
                                    alt=""
                                    style={{ height: '20px', cursor: 'pointer' }}
                                />
                            </Tooltip>
                        </Grid>
                    )}
                    <Grid item>
                        <ReplyButton
                            id={post.id}
                            setCommentedId={setCommentedId}
                            setOpen={setCommentsOpen}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container item>
                        <CommentFormWithMentionableUsers
                            post={post as unknown as PostsObject}
                            commentedId={commentedId}
                            setCommentedId={setCommentedId}
                            onReply={onReply}
                            isEvent={false}
                        />
                    </Grid>
                    <Grid item>
                        <Comments commentsData={commentsData} isOpen={commentsOpen} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
